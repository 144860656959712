<template>
  <main class="layout-main">
    <div class="inner">
      <div class="container-wrapper -bottom">
        <div class="layout-title">
          <h1 class="heading-page"><img src="@/assets/image/title/message.svg" alt="Message"><span class="sub">メッセージ</span></h1>
        </div>


          <div class="container-dou"><img src="@/assets/image/message/dou.svg" alt=""></div>


        <section class="narrow copy-message -center">
          <div class="onebetter"><img src="@/assets/image/message/onebetter.svg" alt=""></div>
          <p>コロナ禍の影響をタップリと受けましたが、<br>
失うことより、新たに生まれたことが多く、<br>
当時では想像出来なかった強くて、柔軟な今があり、<br>
それも、多くの人や企業との繋がりのお陰です。</p>

<p>OneBetterには<br>
沢山の繋がりがあり、沢山の引き出しがあります。</p>


<p>業界や領域は関係なく<br>
あらゆるご相談に迅速に、おこたえ出来るように<br>
フットワーク軽く、常に「一枚上手」を目指すOneBetter</p>

<p>
企画に困ったり、刺激が足りなかったり、どこか繋がりが必要なときなど、<br>
何かお困りの際はOneBetterを思い出して、ご連絡頂けると嬉しいです。</p>
        </section>
      </div>


      <section class="container-bg">
          <div class="container-wrapper -bottom">
            <div class="layout-title -sub">
              <h2 class="heading-page"><img src="@/assets/image/title/profile.svg" alt="Message"><span class="sub">プロフィール</span></h2>
            </div>

            <div class="grid-block -cols2">
              <div><img src="@/assets/image/message/shimokawa.jpg" alt="下川 太樹 Taiki Shimokawa" class="_radius"></div>
              <div>
                <h3 class="heading-name">下川 太樹<span class="sub">Taiki Shimokawa</span></h3>
                <p>大阪生まれの奈良育ち<br class="_pc">
                高校卒業後、吉本興業の劇場駐車場でのアルバイトをきっかけに、約17年間、吉本興業グループに勤め、大阪のお土産「面白い恋人」などを手がける。</p>

                <p>以降は業界を跨いだ転職を幾つか経て、<br class="_pc">
                令和元年7月に現在の（株）One Betterを立ち上げる。</p>

                <p>プライベートでは息子2人、妻1人と共に、<br class="_pc">
                  茅ヶ崎を拠点にして、人生自由形を掲げつつ、<br class="_pc">
                レディオ湘南FM83.1「はい、しょうなんです。」では、吉本芸人と毎週レギュラー出演中。</p>
              </div>

            </div>

          </div>
      </section>


      </div>
  </main>
</template>

<script>
import { onMounted, onUpdated, onUnmounted } from 'vue'

export default {

  setup() {

    onMounted(() => {
      //console.log('onMounted!')
    })
    onUpdated(() => {
      //console.log('updated!')
    })
    onUnmounted(() => {
      //console.log('unmounted!')
    })
  },

}

</script>